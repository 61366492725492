<template>
  <div class="simple-content-block">
    <div :class="[{ centered: centered }, 'container']">
      <h2>{{ simpleContentData.titleText }}</h2>
      <div v-html="simpleContentData.contentText"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleContentBlock',
  props: {
    simpleContentData: {
      type: Object,
      required: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.simple-content-block {
  padding-bottom: 20px;

  .container {
    &.centered {
      h2 {
        @apply text-center;
      }

      div {
        @apply text-center;
      }
    }

    h2 {
      @apply my-4 text-white text-left;
      font-family: OswaldLight, serif;
      font-size: 34px;
      line-height: 1.2em;
    }

    div {
      @apply text-left;
      color: #cfcfcf;
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      h2 {
        font-size: 40px;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      h2 {
        font-size: 55px;
      }
    }
  }
}
</style>
